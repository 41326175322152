.codonLabelLinear {
    cursor: pointer;
}

.codonLabelLinear:hover {
    font-weight: bold;
}

.hoverStem {
    stroke: red;
}

.hoverText {
    fill: red;
}

.hoverBlock {
    stroke: red;
}

.hoverTextContainer {
    stroke: red;
    fill: white;
}
